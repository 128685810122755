import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Title from '../components/Title';
import Paragraph from '../components/Paragraph';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import rehypeReact from 'rehype-react';
import Seo from '../components/seo';

const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {"p": Paragraph},
  }).Compiler

const ForedragTemplate = ({data}) => {
    const {markdownRemark} = data
    const img = getImage(markdownRemark.frontmatter.image)
  return <Layout>
      <Seo title={markdownRemark.frontmatter.title} />
      <div className="flex flex-col lg:flex-row">
        <GatsbyImage className="order-2 lg:w-1/2" image={img} alt="Noder" />
        <div className="order-1 mt-8 lg:w-1/2">
            <Title>{markdownRemark.frontmatter.title}</Title>
            {renderAst(markdownRemark?.htmlAst)}
        </div>
      </div>
  </Layout>;
};

export default ForedragTemplate;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      frontmatter {
        title
        image {
            childImageSharp {
                gatsbyImageData(
                  width: 1000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
        }
      }
    }
  }
`